<template>
  <div class="page">
    <div class="page-main">
      <el-form ref="form" :model="form" :rules="rules" label-width="auto">
        <div class="module-title">{{ $t('accountInfo') }}</div>
        <el-form-item :label="$t('enterpriseManagement.name')" prop="name">
          <el-input
            v-model="form.name"
            :placeholder="$t('enterpriseManagement.namePla')"
            :maxlength="128"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('enterpriseManagement.email')" prop="email">
          <el-input
            v-model="form.email"
            :placeholder="$t('enterpriseManagement.emailPla')"
            :maxlength="128"
          ></el-input>
        </el-form-item>
        <div class="module-title">{{ $t('enterpriseInfo') }}</div>
        <el-form-item :label="$t('enterpriseManagement.area')" prop="area">
          <el-select
            v-model="form.area"
            :placeholder="$t('enterpriseManagement.areaPla')"
            filterable
            style="width: 100%;"
            ><el-option
              v-for="item in countryDics"
              :key="item.id"
              :label="item.title"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('enterpriseManagement.contact')" prop="contact">
          <el-input
            v-model="form.contact"
            :placeholder="$t('enterpriseManagement.contactPla')"
            :maxlength="128"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('enterpriseManagement.phone')" prop="phone">
          <el-input
            v-model="form.phone"
            :placeholder="$t('enterpriseManagement.phonePla')"
            :maxlength="128"
          ></el-input>
        </el-form-item>
        <el-form-item class="footer-btns">
          <el-button class="footer-btn" type="primary" plain @click="$router.go(-1)">{{ $t('cancel') }}</el-button>
          <el-button class="footer-btn" type="primary" @click="submit">{{ $t('save') }}</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { enterpriseAdd, enterpriseEdit, enterpriseQuery } from '@/api/enterprise'

export default {
  name: 'EnterpriseDetails',
  data() {
    // 邮箱验证
    var validateEmail = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('enterpriseManagement.emailRule')))
        this.retrieveData = false
      } else {
        if (value !== '') {
          var reg = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/
          if (!reg.test(value)) {
            callback(new Error(this.$t('enterpriseManagement.emailRule2')))
            this.retrieveData = false
          } else {
            this.retrieveData = true
          }
        }
        callback()
      }
    }
    return {
      form: {
        name: '',
        email: '',
        area: '',
        contact: '',
        phone: '',
        subordinate: 0,
      },
      rules: {
        name: {
          required: true,
          message: this.$t('enterpriseManagement.nameRule'),
          trigger: 'blur',
        },
        email: {
          required: true,
          validator: validateEmail,
          trigger: 'blur',
        },
        area: {
          required: true,
          trigger: 'change',
        },
      },
    }
  },
  computed: {
    ...mapGetters('dist', ['countryDics']),
  },
  created() {
    this.$route.query.id && this.getData(this.$route.query.id)
  },
  methods: {
    getData(id) {
      enterpriseQuery({ id })
        .then(({ data }) => {
          this.form = data.result.rows[0]
        })
        .catch(() => {})
    },
    // 保存
    submit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          const submitApi = this.$route.query.id ? enterpriseEdit : enterpriseAdd
          submitApi(this.form)
            .then(({ data }) => {
              this.$message.success(data.message)
              this.$router.go(-1)
            })
            .catch(() => {})
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@include form-page;
.page {
  .page-main {
    padding: 80px 140px 20px;
    .module-title {
      margin-bottom: 47px;
      font-size: 20px;
      font-weight: bold;
      line-height: 34px;
      color: $--color-primary;
    }
  }
}
</style>